
import { CompanySearch } from './components/Search'

export const HomePage = () => {
   
    return (
        <div>
            <h2>SoS Scraping Tool | BtWebGroup</h2>
            <CompanySearch />
        </div>
    )
}
