import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Input, Tag, theme, Typography } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import { getDataFromCompanyName } from '../../../api/company-search';
export const CompanySearch = () => {
    const { Text } = Typography
    const { token } = theme.useToken();
    const [tags, setTags] = useState([]);
    const [searchType, setSearchType] = useState("Business Name or Organization Number")
    const [fetching, setFetching] = useState(false);
    const [email, setEmail] = useState("");
    const [response, setResponse] = useState({});
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const menuItems = [
        {
            key: 'Business Name or Organization Number',
            label: <div onClick={() => setSearchType("Business Name or Organization Number")}>Business Name or Organization Number</div>,
        },
        {
            key: 'City Name',
            label: <div onClick={() => setSearchType("City Name")}>City Name</div>,
        },
        {
            key: 'Zip Codes',
            label: <div onClick={() => setSearchType("Zip Codes")}>Zip Codes</div>
        },
    ];
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
    };
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            const separatedByComma = inputValue.split(",");
            const cleanValues = separatedByComma.map((value) => value.trim()).filter((value) => value);
            setTags([...tags, ...cleanValues]);
        }
        setInputVisible(false);
        setInputValue('');
    };
    const forMap = (tag) => {
        const tagElem = (
            <Tag
                closable
                onClose={(e) => {
                    e.preventDefault();
                    handleClose(tag);
                }}
            >
                {tag}
            </Tag>
        );
        return tagElem
    }
    const tagChild = tags.map(forMap);
    const tagPlusStyle = {
        background: token.colorBgContainer,
        borderStyle: 'dashed',
        padding: "5px"
    };
    const handleSubmit = async () => {
        setFetching(true);
        setResponse("")
        try {
            const response = await getDataFromCompanyName({ tags, email, searchType });
            console.log(response)
            if (response.data) {
                setResponse(response.data)
            }
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    }
    return (
        <>
            <div
                style={{
                    marginBottom: 16,
                }}
            >
                <TweenOneGroup
                    enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: 'from',
                        duration: 100,
                    }}
                    onEnd={(e) => {
                        if (e.type === 'appear' || e.type === 'enter') {
                            e.target.style = 'display: inline-block';
                        }
                    }}
                    leave={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                        duration: 200,
                    }}
                    appear={false}
                >
                    {tagChild}
                </TweenOneGroup>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", gap: "10px" }}>
                    <Dropdown menu={{ items: menuItems }} placement="bottomLeft">
                        <Button>{searchType}</Button>
                    </Dropdown>
                    <div style={{ marginBottom: "20px" }}>
                        {inputVisible ? (
                            <Input
                                ref={inputRef}
                                type="text"
                                size="small"
                                style={{
                                    width: 150,
                                    height: 30
                                }}
                                value={inputValue}
                                onChange={handleInputChange}
                                onBlur={handleInputConfirm}
                                onPressEnter={handleInputConfirm}
                            />
                        ) : (
                            <Tag onClick={showInput} style={tagPlusStyle}>
                                <PlusOutlined /> Enter Properties
                            </Tag>
                        )}
                    </div>
                    {tags.length > 0 && <Button onClick={() => setTags([])}>Delete All</Button>}
                </div>
                <Input value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter your email" type="email" style={{ width: "200px" }} />
                <div style={{ padding: "10px 0" }}>
                    <Button type='primary' onClick={handleSubmit} disabled={fetching || tags.length === 0 || !email}> {fetching ? <> Scraping... <LoadingOutlined /></> : "Get Excel"} </Button>
                </div>
            </div>

            {fetching && <Text>We're making a scraping request. Hold on for a while.</Text>}
            <Text>{Object.keys(response).length > 0 ? <><Text>{response.message} <br /> Estimated Time is: <b>{response.estimatedTime}</b></Text></> :<></>}</Text>
        </>
    )
}

