import { Routes, Route } from "react-router-dom";
import './App.css';
import { HomePage } from "./pages/home";
import { LoginForm } from "./pages/login";
import Protected from "./wrappers/auth/Protected";
import CheckToken from "./wrappers/auth/CheckToken";

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route element={<CheckToken />}>
          <Route path="/login" element={<LoginForm />} />
        </Route>
        <Route element={<Protected />}>
          <Route path="/" element={<HomePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
