import { globalFetch } from ".";
import { REQUEST_TYPE } from "../constants/api-constants";

export const getDataFromCompanyName = (companyNames) => {
  return globalFetch("scrap-company-data", REQUEST_TYPE.POST, companyNames,"");
};

export const authorizeUser = (userData) => {
  return globalFetch("authorize", REQUEST_TYPE.POST, userData,"", false);
} 

